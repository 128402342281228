import React, { Component } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import NewsStrip from "../../components/news-strip"

class NewsPage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Tetley's win Capita contract" description="Tetley's Coaches wins the contract to provide four coaches to transport Capita employees in Leeds following a comprehensive tender process." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent page-article" style={{ background: 'white' }}>
            <div>
              <h1 className="page-content-heading">Tetley's win Capita contract</h1>
              <p className="news-article-date">January 2015</p>
            </div>

            <div className="news-article-body">
              <p>Tetley's Coaches wins the contract to provide four coaches to transport Capita employees in Leeds following a comprehensive tender process.</p>
              <p>Collecting Capita staff from various locations, we will be transporting them to and from sites at the Arlington Business Centre, Leeds Valley Park and Dearne Valley.</p>
            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default NewsPage
